import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

const LocationHelmet = ({ title, description, keywords, url }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="title" property="title" content={title}/>
    <meta name="description" property="description" content={description} />
    <meta name="keywords" property="keywords" content={keywords} />
    <link rel="canonical" href={url} />
  </Helmet>
)

LocationHelmet.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default LocationHelmet
